import { format } from 'date-fns';
import React, { createContext, useState, useContext, PropsWithChildren, useEffect, useMemo } from 'react';

interface IDateContext {
    currentDate: Date | null;
    setDate: (date: Date | null) => void;
    minDate?: Date;
    registerMinimumDate: (date: Date) => () => void;
}

const DateContext = createContext<IDateContext | undefined>(undefined);

const DateProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    const [currentDate, setCurrentDate] = useState<Date | null>(null);
    const [registeredDates, setRegisteredDates] = useState<number[]>([]);

    const setDate = (date: Date | null) => {
        if(!date) {
            setCurrentDate(null);
            return;
        }

        if(format(date, 'yyyy-MM-dd') === format(new Date(), 'yyyy-MM-dd')) {
            setCurrentDate(null);
            return;
        }
        setCurrentDate(date);
    };

    const registerMinimumDate = (date: Date): (() => void) => {
        const timestamp = date.getTime();
        setRegisteredDates(prevDates => [...prevDates, timestamp]);

        return () => {
            setRegisteredDates(prevDates => prevDates.filter(d => d !== timestamp));
        };
    };

    const minDate = useMemo(() => {
        if (registeredDates.length === 0) return undefined;
        return new Date(Math.min(...registeredDates));
    }, [registeredDates]);

    return (
        <DateContext.Provider value={{ currentDate, setDate, minDate, registerMinimumDate }}>
            {children}
        </DateContext.Provider>
    );
};

export const useDateProvider = (): IDateContext => {
    const context = useContext(DateContext);
    if (context === undefined) {
        throw new Error('useDateProvider must be used within a DateProvider')
    }
    return context;
}

export default DateProvider;