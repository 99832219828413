import React from "react";
import CardTemplate from "@appsinti/ui-core/CardTemplate";
import { MetricData } from "@appsinti/ui-core/styles";
import StatsDay from "../../../../../interfaces/StatsDay";
import { CardTitleComponent, CustomRow } from "../../../styles";

const Reservations: React.FC<{ day: StatsDay }> = ({ day }) => {
  return (
    <CardTemplate
      background={"white"}
      noPadding={true}
      header={
        <CustomRow>
          <CardTitleComponent title={"Reserve"} />
          <CardTitleComponent title={"Cancellations"} />
        </CustomRow>
      }
      body={
        <CustomRow>
          <div className="column">
            <MetricData size={"big"} weight={"bold"}>
              {day.reservationsData.total ? `${day.reservationsData.total.totalReservationCount}r / ${day.reservationsData.total.totalReservationPeopleCount}p` : 'N/A'}
            </MetricData>
          </div>
          <div className="column">
            <MetricData size={"big"} weight={"bold"}>
              {day.reservationsData.total ? `${day.reservationsData.total.cancelledReservationCount}r / ${day.reservationsData.total.cancelledReservationPeopleCount}p` : 'N/A'}
            </MetricData>
          </div>
        </CustomRow>
      }
    />
  );
};

export default Reservations;
