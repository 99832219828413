import React, { useMemo, useState } from "react";
import axios, { AxiosError } from "axios";
import Sales from "../interfaces/Sales";
import Clients from '../interfaces/Clients';
import ClientsVisits from "../interfaces/ClientsVisits";
import { format as dateFormat } from "date-fns";
import Cash from "../interfaces/Cash";
import Cost from "../interfaces/Cost";
import Cancellations from "../interfaces/Cancellations";
import SpecialDaysConfig, { SpecialDay } from "@appsinti/stats-utils/interfaces/SpecialDaysConfig";
import SpecialRangeDatesConfig, { SpecialRangeDate } from "@appsinti/stats-utils/src/interfaces/SpecialRangeDatesConfig";
import auth from "../resources/api/auth";

export type DataContextType = {
  loading: boolean;
  error: string;
  lastUpdate: Date;
  sales: Sales | null,
  cash: Cash | null,
  costs: Cost | null,
  clients: Clients | null,
  cancellations: Cancellations | null,
  specialDaysConfig: SpecialDaysConfig | null,
  specialRangeDatesConfig: SpecialRangeDatesConfig | null,
  fetchSales: () => Promise<void>,
  fetchClients: () => Promise<void>,
  fetchCash: () => Promise<void>,
  fetchCosts: () => Promise<void>,
  fetchCancellations: () => Promise<void>,
  fechSpecialDaysConfig: () => Promise<void>,
  fetchSpecialRangeDatesConfig: () => Promise<void>,
  updateSpecialDay: (specialDay: SpecialDay, dayId: string) => Promise<void>,
  addSpecialDay: (specialDay: SpecialDay) => Promise<void>,
  deleteSpecialDay: (dayId: string) => Promise<void>,
  updateSpecialRangeDate: (specialRangeDate: SpecialRangeDate, rangeDateId: string) => Promise<void>,
  addSpecialRangeDate: (specialRangeDate: SpecialRangeDate) => Promise<void>,
  deleteSpecialRangeDate: (rangeDateId: string) => Promise<void>,
  clientsVisits: ClientsVisits | null,
  fetchClientsData: (start: Date, end: Date) => Promise<void>,
  config: {
    backendUrl: string;
    backendHost: string;
  };
  groupId: number | null;
};

const DataContext = React.createContext<DataContextType>({
  loading: false,
  error: '',
  sales: null,
  clients: null,
  cash: null,
  costs: null,
  cancellations: null,
  specialDaysConfig: null,
  specialRangeDatesConfig: null,
  fetchSales: async () => { },
  fetchClients: async () => { },
  fetchCash: async () => { },
  fetchCosts: async () => { },
  fetchCancellations: async () => { },
  fechSpecialDaysConfig: async () => { },
  fetchSpecialRangeDatesConfig: async () => { },
  updateSpecialDay: async () => { },
  addSpecialDay: async () => { },
  deleteSpecialDay: async () => { },
  updateSpecialRangeDate: async () => { },
  addSpecialRangeDate: async () => { },
  deleteSpecialRangeDate: async () => { },
  lastUpdate: new Date(),
  clientsVisits: null,
  fetchClientsData: async (start: Date, end: Date) => { },
  config: {
    backendUrl: "",
    backendHost: "",
  },
  groupId: null,
});

export const useData = () => React.useContext(DataContext);
const { Authorization } = auth;

export const DataProvider: React.FC<{
  url: string;
  host: string;
  children: any;
  appId: number;
  groupId: number | null;
}> = ({ url, host, children, appId, groupId }) => {
  const [isLoading, setLoading] = useState<{ [key: string]: boolean }>({});
  const [sales, setSales] = useState<Sales | null>(null);
  const [clients, setClients] = useState<Clients | null>(null);
  const [cash, setCash] = useState<Cash | null>(null);
  const [costs, setCosts] = useState<Cost | null>(null);
  const [cancellations, setCancellations] = useState<Cancellations | null>(null);
  const [lastUpdate, setLastUpdate] = useState<Date>(new Date());
  const [clientsVisits, setClientsVisits] = useState<ClientsVisits | null>(null);
  const [specialDaysConfig, setSpecialDaysConfig] = useState<SpecialDaysConfig | null>(null);
  const [specialRangeDatesConfig, setSpecialRangeDatesConfig] = useState<SpecialRangeDatesConfig | null>(null);
  const [error, setError] = useState<string>('');

  async function executeCall(callback: () => Promise<any>, loadingKey = '') {
    setLoading((prev) => ({ ...prev, [loadingKey]: true }));
    setError('');
    try {
      const response = await callback();
      return response.data;
    } catch (e) {
      console.error(e);
      let message = '';
      if (axios.isAxiosError(e)) {
        message = (e as AxiosError).message;
      } else {
        message = 'Unexpected error';
      }
      setError(message);
      throw e;
    } finally {
      setLastUpdate(new Date());
      setLoading((prev) => ({ ...prev, [loadingKey]: false }));
    }
  }

  const fetchSales = async () => {
    const response = await executeCall(() => axios.get<Sales>(`${url}/appsinti/stats/sales`, { headers: { "app-id": appId, Authorization } }), "sales");
    setSales(response);
  };

  const fetchClients = async () => {
    const response = await executeCall(() => axios.get<Sales>(`${url}/appsinti/stats/clients`, { headers: { "app-id": appId, Authorization } }), "clients");
    setClients(response);
  };

  const fetchClientsData = async (start: Date, end: Date): Promise<void> => {
    const sDate: string = dateFormat(start, 'yyyy-MM-dd');
    const eDate: string = dateFormat(end, 'yyyy-MM-dd');
    let completeUrl = `${url}/appsinti/stats/clients-visits?weekly_time_from=${sDate}&weekly_time_to=${eDate}`;
    const response = await executeCall(() => axios.get<ClientsVisits>(completeUrl, { headers: { "app-id": appId, Authorization } }), "clients");
    setClientsVisits(response);
  }

  const fetchCash = async () => {
    const response = await executeCall(() => axios.get<Cash>(`${url}/appsinti/stats/cash`, { headers: { "app-id": appId, Authorization } }), "cash");
    setCash(response);
  };

  const fetchCosts = async () => {
    const response = await executeCall(() => axios.get<Cost>(`${url}/appsinti/stats/costs`, { headers: { "app-id": appId, Authorization } }), "costs");
    setCosts(response);
  };

  const fetchCancellations = async () => {
    const response = await executeCall(() => axios.get<Cancellations>(`${url}/appsinti/stats/cancellations`, { headers: { "app-id": appId, Authorization } }), "cancellation");
    setCancellations(response);
  };

  const fechSpecialDaysConfig = async () => {
    const response = await executeCall(() => axios.get<SpecialDaysConfig>(`${url}/appsinti/stats/config/special-days`, { headers: { "app-id": appId, Authorization } }), "special-days-config");
    setSpecialDaysConfig(response);
  }

  const updateSpecialDay = async (specialDay: SpecialDay, dayId: string) => {
    const response = await executeCall(() => axios.patch<SpecialDaysConfig>(`${url}/appsinti/stats/config/special-days`, { specialDay, dayId }, { headers: { "app-id": appId, Authorization } }), "update-special-day");
    setSpecialDaysConfig(response);
  }

  const addSpecialDay = async (specialDay: SpecialDay) => {
    const response = await executeCall(() => axios.post<SpecialDaysConfig>(`${url}/appsinti/stats/config/special-days`, { specialDay }, { headers: { "app-id": appId, Authorization } }), "add-special-day");
    setSpecialDaysConfig(response);
  }

  const deleteSpecialDay = async (dayId: string) => {
    const response = await executeCall(() => axios.delete<SpecialDaysConfig>(`${url}/appsinti/stats/config/special-days?dayId=${dayId}`, { headers: { "app-id": appId, Authorization } }), "add-special-day");
    setSpecialDaysConfig(response);
  }

  const fetchSpecialRangeDatesConfig = async () => {
    const response = await executeCall(() => axios.get<SpecialRangeDate>(`${url}/appsinti/stats/config/range-dates`, { headers: { "app-id": appId, Authorization } }), "special-range-dates-config");
    setSpecialRangeDatesConfig(response);
  }

  const updateSpecialRangeDate = async (specialRangeDate: SpecialRangeDate, rangeDateId: string) => {
    const response = await executeCall(() => axios.patch<SpecialRangeDate>(`${url}/appsinti/stats/config/range-dates`, { 'rangeDate': specialRangeDate, rangeDateId }, { headers: { "app-id": appId, Authorization } }), "update-special-range-dates");
    setSpecialRangeDatesConfig(response);
  }

  const addSpecialRangeDate = async (specialRangeDate: SpecialRangeDate) => {
    const response = await executeCall(() => axios.post<SpecialRangeDate>(`${url}/appsinti/stats/config/range-dates`, { 'rangeDate': specialRangeDate }, { headers: { "app-id": appId, Authorization } }), "add-special-range-dates");
    setSpecialRangeDatesConfig(response);
  }

  const deleteSpecialRangeDate = async (rangeDateId: string) => {
    const response = await executeCall(() => axios.delete<SpecialRangeDate>(`${url}/appsinti/stats/config/range-dates?rangeDateId=${rangeDateId}`, { headers: { "app-id": appId, Authorization } }), "add-special-range-dates");
    setSpecialRangeDatesConfig(response);
  }

  const loading = useMemo(() => Object.entries(isLoading).some((([, value]) => value)), [isLoading]);

  return (
    <DataContext.Provider
      value={{
        loading,
        error,
        sales,
        clients,
        cash,
        costs,
        cancellations,
        specialDaysConfig,
        specialRangeDatesConfig,
        fetchSales,
        fetchClients,
        fetchCash,
        fetchCosts,
        fetchCancellations,
        fechSpecialDaysConfig,
        fetchSpecialRangeDatesConfig,
        updateSpecialDay,
        addSpecialDay,
        deleteSpecialDay,
        updateSpecialRangeDate,
        addSpecialRangeDate,
        deleteSpecialRangeDate,
        lastUpdate,
        clientsVisits,
        fetchClientsData: async (start: Date, end: Date) => { await fetchClientsData(start, end) },
        config: { backendUrl: url, backendHost: host, },
        groupId,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
