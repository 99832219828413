import React from "react";
import CardTemplate from "@appsinti/ui-core/CardTemplate";
import { MetricData } from "@appsinti/ui-core/styles";
import { RIGHT_DARK } from "@etiquette-ui/colors";
import { CardTitleComponent, CustomRow } from "../../../styles";
import { formatMoney } from "../../../../../../../utils";
import StatsPreview from "../../../../../interfaces/StatsPreview";

const SalesGuestRow: React.FC<{ preview: StatsPreview }> = ({ preview }) => {
  return (
    <CardTemplate
      background={"white"}
      noPadding={true}
      header={
        <CustomRow>
          <CardTitleComponent title={"Sales/Guest"} />
          <CardTitleComponent title={"Lunch"} />
          <CardTitleComponent title={"Dinner"} />
        </CustomRow>
      }
      body={
        <CustomRow>
          <div className="column">
            <MetricData size={"biggest"} weight={"bold"} color={RIGHT_DARK}>
              {formatMoney(preview.salesData.total.salesPerGuest, 0)}
            </MetricData>
          </div>
          <div className="column">
            <MetricData size={"biggest"} weight={"bold"} color={RIGHT_DARK}>
              {formatMoney(preview.salesData.lunch!.salesPerGuest, 0)}
            </MetricData>
          </div>
          <div className="column">
            <MetricData size={"biggest"} weight={"bold"} color={RIGHT_DARK}>
              {formatMoney(preview.salesData.dinner!.salesPerGuest, 0)}
            </MetricData>
          </div>
        </CustomRow>
      }
    />
  );
};

export default SalesGuestRow;
