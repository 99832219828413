import React from "react";
import CardTemplate from "@appsinti/ui-core/CardTemplate";
import { MetricData } from "@appsinti/ui-core/styles";
import { CustomRow, MainColumn } from "../../../styles";
import StatsDay from "../../../../../interfaces/StatsDay";

const Reservations: React.FC<{ day: StatsDay }> = ({ day }) => {
  return (
    <CardTemplate
      background={"white"}
      noPadding={true}
      body={
        <CustomRow>
          <MainColumn className="main">
            <div className="column">
              <MetricData size={"medium"} weight={"bold"}>
                RESERVE
              </MetricData>
              <MetricData size={"bigger"} weight={"bold"}>
                {day.reservationsData.total ? `${day.reservationsData.total.totalReservationCount}r / ${day.reservationsData.total.totalReservationPeopleCount}p` : 'N/A'}
              </MetricData>
            </div>
            <div className="column">
              <MetricData size={"medium"} weight={"bold"}>
                CANCELLATIONS
              </MetricData>
              <MetricData size={"bigger"} weight={"bold"}>
                {day.reservationsData.total ? `${day.reservationsData.total.cancelledReservationCount}r / ${day.reservationsData.total.cancelledReservationPeopleCount}p` : 'N/A'}
              </MetricData>
            </div>
          </MainColumn>
        </CustomRow>
      }
    />
  );
};

export default Reservations;
