import Axios from "axios";
import { BACKEND } from "../constants/config";
import IArea from "../interfaces/IArea";
import IVideo from "../interfaces/IVideo";
import { Language } from "@appsinti/i18n/interfaces/Language";
import auth from "../resources/api/auth";

const url = `${BACKEND}/appsinti/cameras`
const starredCamerasKey = 'starred_cameras'
const { Authorization } = auth;

const getAreas = async (appId: number): Promise<IArea[]> => {
    try {
        const response = await Axios.get<IArea[]>(url, { headers: { "app-id": appId, Authorization } });
        return response.data as IArea[]
    } catch (error) {
        return []
    }
}

export const getData = async (appId: number): Promise<{ areas: IArea[], previews: IVideo[], starred: IVideo[] }> => {
    try {
        const areas = await getAreas(appId);
        const previews = await getPreviewCameras(appId);
        const rawData = localStorage.getItem(starredCamerasKey);
        let starredCameras: IVideo[] = [];

        if (!!rawData) {
            const starredCamerasId = JSON.parse(rawData) as { [key: string]: string[] };
            starredCameras = getCamerasFromIds(starredCamerasId[appId], areas);
        }

        if (!!previews) return { areas, previews, starred: starredCameras };

        const defaultPreviewCameras = getDefaultCameras(areas);
        return { areas, previews: defaultPreviewCameras, starred: starredCameras }
    } catch (error) {
        return { areas: [], previews: [], starred: [] };
    }
}

export const getCamerasFromIds = (ids: string[], areas: IArea[]): IVideo[] => {
    const cameras: IVideo[] = [];

    if (!ids) return cameras;

    for (const area of areas) {
        if (cameras.length === ids.length) {
            break
        }
        for (const camera of area.cameras) {
            if (ids.includes(camera.id)) {
                cameras.push({ ...camera, areaId: area.id });
            }
        }
    }
    return cameras;
}

const getDefaultCameras = (areas: IArea[]): IVideo[] => {
    const defaultCameras: IVideo[] = []
    const maxDefaultCamerasCount = 2

    for (const area of areas) {
        if (defaultCameras.length === maxDefaultCamerasCount) {
            break;
        }
        defaultCameras.push(area.cameras[0]);
    }

    return defaultCameras;
}

export const modifyNameById = async (id: string, name: string, lang: Language, appId: number): Promise<boolean> => {
    try {
        await Axios.patch<IArea>(url, { id, name, lang }, { headers: { "app-id": appId, Authorization } })
        return true
    } catch (e) {
        return false
    }
}

export const getPreviewCameras = async (appId: number): Promise<IVideo[]> => {
    try {
        const response = await Axios.get<IVideo[]>(`${url}/previews`, { headers: { "app-id": appId, Authorization } });
        return response.data as IVideo[];
    } catch (e) {
        return [];
    }
}

export const modifyPreviewCameras = async (previews: string[], appId: number): Promise<boolean> => {
    try {
        await Axios.patch<IArea>(`${url}/previews`, { previews }, { headers: { "app-id": appId, Authorization } })
        return true
    } catch (e) {
        return false
    }
}

export const modifyStarredCameras = (ids: string[], appId: number) => {
    const rawData = localStorage.getItem(starredCamerasKey);
    let starredCameras: { [key: string]: string[] } = {};

    if (!!rawData) {
        starredCameras = JSON.parse(rawData) as { [key: string]: string[] };
    }
    starredCameras[appId] = ids;
    localStorage.setItem(starredCamerasKey, JSON.stringify(starredCameras))
}