let token: null | string;

export function getToken() {
    if (!token) {
        const auth = localStorage.getItem("auth") ?? '';
        let parsedAuth = null;

        try {
            parsedAuth = JSON.parse(auth);
        } catch (e) {
            console.error(e);
        }

        token = parsedAuth !== null ? parsedAuth.token : null;
    }

    return token;
}


const Authorization = { Authorization: getToken() ?? '' }

export default Authorization;