import React from "react";
import StatsPreview from "../../../../../interfaces/StatsPreview";
import {
  SplitsContainer,
} from "../../../styles";

import SalesColumn from "./SalesColumn";
import SalesGuestColumn from "./SalesGuestColumn";
import Reservations from "./Reservations";
import ClosedSalesCash from "./ClosedSalesCash";
import Sales from "./Sales";

const FullWidthHalfHeight: React.FC<{ preview: StatsPreview }> = ({
  preview,
}) => {
  if (!!preview.salesData.lunch && !!preview.salesData.dinner) {
    return (
      <>
        <SplitsContainer flex={4}>
          <SalesColumn preview={preview} />
          <SalesGuestColumn preview={preview} />
        </SplitsContainer>
        <Reservations preview={preview} />
      </>
    );
  }

  return (
    <>
      <Sales preview={preview} />
      <ClosedSalesCash preview={preview} />
      <Reservations preview={preview} />
    </>
  );
};

export default FullWidthHalfHeight;
