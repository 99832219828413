import React from "react";
import CardTemplate from "@appsinti/ui-core/CardTemplate";
import { MetricData } from "@appsinti/ui-core/styles";
import StatsPreview from "../../../../../interfaces/StatsPreview";
import { CustomRow, MainColumn } from "../../../styles";

const Reservations: React.FC<{ preview: StatsPreview }> = ({ preview }) => {
  return (
    <CardTemplate
      background={"white"}
      noPadding={true}
      body={
        <CustomRow>
          <MainColumn className="main">
            <div className="column">
              <MetricData size={"medium"} weight={"bold"}>
                PEOPLE
              </MetricData>
              <MetricData size={"bigger"} weight={"bold"}>
                {`${preview.reservationsData.total.customerSeatedCount}/${
                  preview.reservationsData.total.customerDoneCount +
                  preview.reservationsData.total.customerSeatedCount
                }`}
              </MetricData>
            </div>
            <div className="column">
              <MetricData size={"medium"} weight={"bold"}>
                RESERVE
              </MetricData>
              <MetricData size={"bigger"} weight={"bold"}>
                {`${preview.reservationsData.total.reservationCount}r / ${preview.reservationsData.total.reservationPeopleCount}p`}
              </MetricData>
            </div>
          </MainColumn>
        </CustomRow>
      }
    />
  );
};

export default Reservations;
