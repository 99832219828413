import React from "react";
import Reservations from "./Reservations";
import CashCancellationsCourtesy from "./CashCancellationsCourtesy";
import Sales from "./Sales";
import StatsDay from "../../../../../interfaces/StatsDay";

const FullWidthHalfHeight: React.FC<{ day: StatsDay }> = ({ day }) => {
  return (
    <>
      <Sales day={day} />
      <CashCancellationsCourtesy day={day} />
      <Reservations day={day} />
    </>
  );
};

export default FullWidthHalfHeight;
