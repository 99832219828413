import React from "react";
import Sales from "./Sales";
import ClosedSalesCash from "./ClosedSalesCash";
import CancellationCourtesy from "./CancellationCourtesy";
import Reservations from "./Reservations";
import StatsDay from "../../../../../interfaces/StatsDay";

const FullWidthHalfHeight: React.FC<{ day: StatsDay }> = ({
  day,
}) => {
  return (
    <>
      <Sales day={day} />
      <ClosedSalesCash day={day} />
      <Reservations day={day} />
      <CancellationCourtesy day={day} />
    </>
  );
};

export default FullWidthHalfHeight;
