import React from "react";
import CardTemplate from "@appsinti/ui-core/CardTemplate";
import { MetricData } from "@appsinti/ui-core/styles";
import { RIGHT_DARK } from "@etiquette-ui/colors";
import { CardTitleComponent, CustomRow } from "../../../styles";
import { formatMoneyMK, formatPercentage } from "../../../../../../../utils";
import StatsPreview from "../../../../../interfaces/StatsPreview";

const ClosedSalesCash: React.FC<{ preview: StatsPreview }> = ({ preview }) => {
  return (
    <CardTemplate
      background={"white"}
      noPadding={true}
      header={
        <CustomRow>
          <CardTitleComponent title={"Closed Sales"} />
          <CardTitleComponent title={"Cash %"} />
        </CustomRow>
      }
      body={
        <CustomRow>
          <div className="column">
            <MetricData size={"bigger"} weight={"bold"} color={RIGHT_DARK}>
              {formatMoneyMK(preview.salesData.total.closedSales)}
            </MetricData>
          </div>
          <div className="column">
            <MetricData size={"bigger"} weight={"bold"} color={RIGHT_DARK}>
              {formatPercentage(preview.salesData.total.cash, 1)}
            </MetricData>
          </div>
        </CustomRow>
      }
    />
  );
};

export default ClosedSalesCash;
