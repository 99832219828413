import React from "react";
import FullWidthHalfHeight from "./configurations/FullWidthHalfHeight";
import FullWidthThirdHeight from "./configurations/FullWidthThirdHeight";
import HalfWidthHalfHeight from "./configurations/HalfWidthHalfHeight";
import HalfWidthThirdHeight from "./configurations/HalfWidthThirdHeight";
import {
  HeightRestriction,
  WidthRestriction,
} from "../../../interfaces/Restrictions";
import StatsDay from "../../../interfaces/StatsDay";
import { Ring } from "@uiball/loaders";
import { EmptyContainer, EmptyText } from "../styles";

const DaySection: React.FC<{
  day: StatsDay | null;
  loading: boolean;
  heightRestriction: HeightRestriction;
  widthRestriction: WidthRestriction;
}> = ({ day, loading, heightRestriction, widthRestriction }) => {
  if (loading) {
    return (
      <EmptyContainer>
        <Ring color={"white"} size={30} />
      </EmptyContainer>
    );
  }

  if (!day) {
    return (
      <EmptyContainer>
        <EmptyText className={""}>No data available</EmptyText>
      </EmptyContainer>
    );
  }

  switch (widthRestriction) {
    case "full-width":
      if (heightRestriction === "half")
        return <FullWidthHalfHeight day={day} />;
      if (heightRestriction === "third")
        return <FullWidthThirdHeight day={day} />;
      return null;
    case "half-width":
      if (heightRestriction === "half")
        return <HalfWidthHalfHeight day={day} />;
      if (heightRestriction === "third")
        return <HalfWidthThirdHeight day={day} />;
      return null;
    default:
      return null;
  }
};

export default DaySection;
