import React from "react";
import { usePreviewData } from "../data";
import styled from "styled-components";
import MainCard from "@appsinti/ui-core/MainCard";
import PreviewSection from "./PreviewSection";
import {
  HeightRestriction,
  WidthRestriction,
} from "../../interfaces/Restrictions";
import DaySection from "./DaySection";
import { useDateProvider } from "@appsinti/date-provider";

const CustomMainCard = styled(MainCard)`
  > .body {
    gap: 5px;
    padding: 5px;
  }
`;

const Body: React.FC<{
  heightRestriction: HeightRestriction;
  widthRestriction: WidthRestriction;
}> = ({ heightRestriction, widthRestriction }) => {
  const { preview, day, loadingPreview, loadingDay, lastUpdate } =
    usePreviewData();
  const { currentDate } = useDateProvider();

  return (
    <CustomMainCard title={"Sales"} lastUpdatedTime={lastUpdate}>
      {!currentDate && (
        <PreviewSection
          preview={preview}
          loading={loadingPreview}
          heightRestriction={heightRestriction}
          widthRestriction={widthRestriction}
        />
      )}
      {currentDate && (
        <DaySection
          day={day}
          loading={loadingDay}
          heightRestriction={heightRestriction}
          widthRestriction={widthRestriction}
        />
      )}
    </CustomMainCard>
  );
};

export default Body;
