import React from "react";
import StatsPreview from "../../../../../interfaces/StatsPreview";

import SalesRow from './SalesRow';
import SalesGuestRow from './SalesGuestRow';
import Reservations from './Reservations';
import Sales from './Sales';
import ClosedSalesCash from './ClosedSalesCash';

const FullWidthHalfHeight: React.FC<{ preview: StatsPreview }> = ({
  preview,
}) => {
  if (!!preview.salesData.lunch && !!preview.salesData.dinner) {
    return (
      <>
        <SalesRow preview={preview} />
        <SalesGuestRow preview={preview} />
        <Reservations preview={preview} />
      </>
    );
  }

  return (
    <>
      <Sales preview={preview} />
      <ClosedSalesCash preview={preview} />
      <Reservations preview={preview} />
    </>
  );
};

export default FullWidthHalfHeight;
