import React from 'react';
import { CardTitle, Row } from "@appsinti/ui-core/styles";
import styled from "styled-components";
import { P } from '@etiquette-ui/typography';

const CustomCardTitle = styled(CardTitle)`
  font-size: 12px;
  font-weight: 700;

  @media screen and (max-width: 380px) {
    font-size: 10px;
  }
  @media screen and (max-height: 750px){
    font-size: 10px;
  }
  @media screen and (max-height: 710px){
    font-size: 9px;
  }
`;

export  const CardTitleComponent: React.FC<{ title: string }> = ({ title }) => {
  return (
    <div className="column">
      <CustomCardTitle>{title}</CustomCardTitle>
    </div>
  );
};

export const CustomRow = styled(Row)`
  text-align: center;
  gap: 2px;
  display: flex;
  margin: 0;
  padding: 0;
  flex: 1;
  align-items: center;
  div {
    display: flex;
    flex: 1;
    display: flex;
    gap: 3px;

    @media screen and (max-height: 750px){
      gap: 2px;
    }

    &.column {
      flex-direction: column;
    }
    &.row {
      flex-direction: row;
    }

    span {
      flex: 1;
    }
  }
`;

export const BottomContainer = styled.div`
  display: flex;
  flex: 2;
  gap: 5px;
`;

export const MainColumn = styled.div`
  &.main {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 15px;

    @media screen and (max-width: 380px) {
      gap: 7px;
    }

    @media screen and (max-height: 750px) {
      gap: 7px;
    }
  }

  &.smaller {
    gap: 10px;

    @media screen and (max-width: 380px) {
      gap: 5px;
    }

    @media screen and (max-height: 750px) {
      gap: 5px;
    }
  }

  .column {
    flex: 1;
  }
`;

export const SplitsContainer = styled.div<{flex?: number}>`
  flex: ${({flex}) => flex ? flex : 5};
  display: flex;
  gap: 5px;

  @media screen and (max-height: 750px) {
    flex: 5;
  }
`;

export const EmptyContainer = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const EmptyText = styled(P)`
  color: white;
  font-size: 1.6rem;
`;