import React, { PropsWithChildren } from "react";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import CurrentTemperature from "../interfaces/CurrentTemperature";
import HourTemperature from "../interfaces/HourTemperature";
import DayTemperature from "../interfaces/DayTemperature";
import { BACKEND } from "../resources/constants/urls";
import auth from "../resources/api/auth";

export type DataContextType = {
  loading: boolean;
  current: CurrentTemperature | null;
  hourly: HourTemperature[] | null;
  daily: DayTemperature[] | null;
  lastUpdate: Date;
};

const DataContext = React.createContext<DataContextType>({
  loading: false,
  current: null,
  hourly: null,
  daily: null,
  lastUpdate: new Date(),
});

export const useData = () => React.useContext(DataContext);
const { Authorization } = auth;

export const DataProvider: React.FC<PropsWithChildren<{
  appId: number;
}>> = ({ appId, children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [current, setCurrent] = useState<CurrentTemperature | null>(null);
  const [hourly, setHourly] = useState<HourTemperature[] | null>(null);
  const [daily, setDaily] = useState<DayTemperature[] | null>(null);
  const [lastUpdate, setLastUpdate] = useState<Date>(new Date());

  const fetchData = async () => {
    setLoading(true);
    const current = await axios.get<CurrentTemperature>(
      `${BACKEND}/appsinti/weather/current`, { headers: { "app-id": appId, Authorization } }
    );
    setCurrent(current.data);
    const hourly = await axios.get<HourTemperature[]>(
      `${BACKEND}/appsinti/weather/hourly`, { headers: { "app-id": appId, Authorization } }
    );
    setHourly(hourly.data);
    const daily = await axios.get<DayTemperature[]>(
      `${BACKEND}/appsinti/weather/daily`, { headers: { "app-id": appId, Authorization } }
    );
    setDaily(daily.data);
    setLastUpdate(new Date());
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <DataContext.Provider
      value={{
        loading,
        current,
        hourly,
        daily,
        lastUpdate,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
