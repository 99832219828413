import React from "react";
import StatsPreview from "../../../../../interfaces/StatsPreview";
import { BottomContainer } from "../../../styles";

import SalesRow from './SalesRow';
import SalesGuestRow from './SalesGuestRow';
import Reservations from './Reservations';
import CancellationCourtesy from './CancellationCourtesy';
import ClosedSalesCash from './ClosedSalesCash';
import Sales from './Sales';

const FullWidthHalfHeight: React.FC<{ preview: StatsPreview }> = ({
  preview,
}) => {
  if (!!preview.salesData.lunch && !!preview.salesData.dinner) {
    return (
      <>
        <SalesRow preview={preview} />
        <SalesGuestRow preview={preview} />
        <ClosedSalesCash preview={preview} />
        <BottomContainer>
          <Reservations preview={preview} />
          <CancellationCourtesy preview={preview} />
        </BottomContainer>
      </>
    );
  }

  return (
    <>
      <Sales preview={preview} />
      <ClosedSalesCash preview={preview} />
      <BottomContainer>
        <Reservations preview={preview} />
        <CancellationCourtesy preview={preview} />
      </BottomContainer>
    </>
  );
};

export default FullWidthHalfHeight;
