import React from "react";
import StatsPreview from "../../../../../interfaces/StatsPreview";
import { BottomContainer } from "../../../styles";
import Reservations from './Reservations';
import CancellationCourtesy from './CancellationCourtesy';
import ClosedSalesCash from './ClosedSalesCash';
import Sales from './Sales';
import StatsDay from "../../../../../interfaces/StatsDay";

const FullWidthHalfHeight: React.FC<{ day: StatsDay }> = ({
  day,
}) => {
  return (
    <>
      <Sales day={day} />
      <ClosedSalesCash day={day} />
      <BottomContainer>
        <Reservations day={day} />
        <CancellationCourtesy day={day} />
      </BottomContainer> 
    </>
  );
};

export default FullWidthHalfHeight;
