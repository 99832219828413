import React from "react";
import CardTemplate from "@appsinti/ui-core/CardTemplate";
import { MetricData } from "@appsinti/ui-core/styles";
import { RIGHT_DARK } from "@etiquette-ui/colors";
import { CardTitleComponent, CustomRow, MainColumn } from "../../../styles";
import { formatMoney } from "../../../../../../../utils";
import StatsPreview from "../../../../../interfaces/StatsPreview";

const SalesGuestColumn: React.FC<{ preview: StatsPreview }> = ({ preview }) => {
  return (
    <CardTemplate
      background={"white"}
      noPadding={true}
      header={
        <CustomRow>
          <CardTitleComponent title={"Sales/Guest"} />
        </CustomRow>
      }
      body={
        <CustomRow>
          <MainColumn className="main smaller">
            <div className="column">
              <MetricData size={"small"} weight={"bold"} color={RIGHT_DARK}>
                ALL DAY
              </MetricData>
              <MetricData size={"bigger"} weight={"bold"} color={RIGHT_DARK}>
                {formatMoney(preview.salesData.total.salesPerGuest, 0)}
              </MetricData>
            </div>
            <div className="column">
              <MetricData size={"small"} weight={"bold"} color={RIGHT_DARK}>
                LUNCH
              </MetricData>
              <MetricData size={"bigger"} weight={"bold"} color={RIGHT_DARK}>
                {formatMoney(preview.salesData.lunch!.salesPerGuest, 0)}
              </MetricData>
            </div>
            <div className="column">
              <MetricData size={"small"} weight={"bold"} color={RIGHT_DARK}>
                DINNER
              </MetricData>
              <MetricData size={"bigger"} weight={"bold"} color={RIGHT_DARK}>
                {formatMoney(preview.salesData.dinner!.salesPerGuest, 0)}
              </MetricData>
            </div>
          </MainColumn>
        </CustomRow>
      }
    />
  );
};

export default SalesGuestColumn;
