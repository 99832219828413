import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import useWindowSize from "customHooks/useWindowSize";
import { useApps } from "pages/home/AppProvider";
import { useNavigate } from 'react-router-dom';
import Body from "./components/Body";

const Summary: React.FC = () => {
  const [, windowHeight] = useWindowSize();
  const [headerHeight, setHeaderHeight] = useState<number | undefined>(0);
  const [bodyHeight, setBodyHeight] = useState<number>(0);
  const { summaryApps, apps } = useApps();
  const navigate = useNavigate();

  useEffect(() => {
    if (headerHeight !== undefined && headerHeight >= 0 && windowHeight >= 0) {
      setBodyHeight(windowHeight - headerHeight);
    }
  }, [windowHeight, headerHeight]);

  useEffect(()=>{
    if(!Array.isArray(summaryApps)) return;
    if(summaryApps.length !== 0) return;
    if(!!apps && Object.keys(apps).length > 0) {
      navigate(`/${Object.keys(apps)[0]}`);
    }
  }, [summaryApps, navigate, apps]);

  return (
    <>
      <Header setHeaderHeight={setHeaderHeight} headerHeight={headerHeight} />
      <Body bodyHeight={bodyHeight} />
    </>
  );
};

export default Summary;
