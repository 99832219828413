const COLORS = [
  "#89E231",
  "#EB6446",
  "#BB20DB",
  "#EBA631",
  "#0200B0",
  "#6A7B80",
  "#FF0000",
  "#18FFF9",
  "#FF78FF",
  "#008621",
  "#5191DE",
];

export default COLORS;
