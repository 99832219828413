import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player/file";
import styled from "styled-components";

import MainHeaderApp from "@appsinti/ui-core/header";

import TemperatureProvider from "@appsinti/weather-frontend/providers/temperature";

import Current from "./components/Current";
import Hourly from "./components/Hourly";
import Weekly from "./components/Weekly";
import SwitchButton from "./components/SwitchButton";

import { APP_PUBLIC_NAME } from "@appsinti/weather-frontend/constants/config";
import useI18n from "@appsinti/i18n/useI18n";

import { DataProvider, useData } from "./data";
import { Ring } from "@uiball/loaders";
import { BG_DARK, TEXT_PLACEHOLDER } from "@etiquette-ui/colors";

import { getDisplayDate } from "@appsinti/common-utils";
import SocketProvider, { useSocket } from "../Socket";
import { BACKEND } from "../resources/constants/urls";
import axios, { AxiosError } from "axios";
import Authorization from "../resources/api/auth";

const Body = styled.div`
  position: relative;
  width: 100%;

  .video-preview {
    overflow: hidden;
    max-height: 18rem;
    min-height: 18rem;
    background-color: ${TEXT_PLACEHOLDER};
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
  }

  .container {
    width: 100%;
    padding: 0px 15px;
    margin-top: -6rem;

    z-index: 2;
    position: relative;

    .row.mt-4 {
      margin-top: 1.5rem;
    }
  }
`;

const Card = styled.div<{ padding?: string; borderRadius?: string }>`
  max-width: 100%;
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : "8px")};
  margin-bottom: 5px;
  overflow: hidden;
  background-color: white;
  display: flex;
  flex-direction: column;

  .header {
    width: 100%;
    padding: 4px;
    background-color: ${BG_DARK};
  }

  .body {
    padding: ${({ padding }) => (padding ? padding : "15px 24px")};
    width: 100%;
    flex: 1 1 auto;
  }
`;

const CardHeader = styled.div`
  width: 100%;
  padding: 5px 10px;
  font-weight: 600;
  font-size: 1.2rem;
  color: white;
`;

function supportsHLS() {
  var video = document.createElement("video");
  return Boolean(
    video.canPlayType("application/vnd.apple.mpegURL") ||
    video.canPlayType("audio/mpegurl")
  );
}

const VideoUI: React.FC = () => {
  const { socket } = useSocket();
  const [videoSrc, setVideoSrc] = useState("");
  const [streamReady, setStreamReady] = useState<boolean>(false);

  useEffect(() => {
    if (!socket) return;
    socket.emit("start-weather-cam");

    socket.on("stream-ready", ({ streamUrl }) => {
      setVideoSrc(streamUrl);
    });

    return () => {
      socket.emit("stop-weather-cam");
      socket.off("stream-ready");
    };
  }, [socket]);

  useEffect(() => {
    if (!videoSrc || streamReady) return;
    const interval = setInterval(async () => {
      try {
        await axios.get(`${BACKEND}/appsinti/weather${videoSrc}?nocache=${Date.now()}`, { headers: Authorization });
        setStreamReady(true);
        clearInterval(interval);
      } catch (e: AxiosError | any) {
        console.error(e);
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [videoSrc]);

  return !!videoSrc && streamReady ? (
    supportsHLS() ? (
      <video
        src={`${BACKEND}/appsinti/weather${videoSrc}?nocache=${Date.now()}`}
        width={"100%"}
        height={"auto"}
        autoPlay={true}
        muted={true}
        style={{ objectFit: "cover" }}
        playsInline={true}
      />
    ) : (
      <ReactPlayer
        url={`${BACKEND}/appsinti/weather${videoSrc}?nocache=${Date.now()}`}
        width={"100%"}
        height={"auto"}
        playing={true}
        muted={true}
        style={{ objectFit: "cover" }}
        playsinline={true}
        config={{ forceHLS: true }}
      />
    )
  ) : (
    <Ring color={BG_DARK} size={22} />
  );
};

const Home: React.FC<{ groupId: number | null }> = ({ groupId }) => {
  const { translateObject } = useI18n();
  const { loading, current, lastUpdate } = useData();

  return (
    <TemperatureProvider>
      <MainHeaderApp
        title={translateObject(APP_PUBLIC_NAME)}
        lastUpdated={lastUpdate}
        backButtonLocation={groupId ? `/${groupId}` : '..'}
      />
      <Body>
        <div className="video-preview">
          <VideoUI />
          <SwitchButton />
        </div>
        <div className="container">
          <div className="row">
            <Card borderRadius="24px 24px 8px 8px" padding="0">
              <div className="header">
                <CardHeader>
                  {getDisplayDate(lastUpdate, "EEEE, d MMMM yyyy")}
                </CardHeader>
              </div>
              <div className="body">
                {loading || !current ? (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Ring color={BG_DARK} size={22} />
                  </div>
                ) : (
                  <>
                    <Current />
                    <Hourly />
                  </>
                )}
              </div>
            </Card>
          </div>
          <div className="row mt-4">
            <Card>
              <div className="body">
                <Weekly />
              </div>
            </Card>
          </div>
        </div>
      </Body>
    </TemperatureProvider>
  );
};

const HomeContainer: React.FC<{ appId: number, groupId: number | null }> = ({ appId, groupId }) => {
  return (
    <DataProvider appId={appId}>
      <SocketProvider appId={appId}>
        <Home groupId={groupId} />
      </SocketProvider>
    </DataProvider>
  );
};

export default HomeContainer;
