import React, { useRef, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Container from "@appsinti/ui-core/header/Container";
import {
  getFormattedDate,
  getToday,
} from "@appsinti/ui-core/dateAndTime/utils";

import useI18n from "@appsinti/i18n/useI18n";
import { ShowMenu } from "pages/home/components/ShowMenu";
import Icon from "@etiquette-ui/icons";
import { useUpdate } from "@appsinti/trigger-update";
import { Ring } from "@uiball/loaders";
import { DISABLED_BUTTON, PRIMARY } from "@etiquette-ui/colors";

import { useDateProvider } from "@appsinti/date-provider";
import { useApps } from "pages/home/AppProvider";
import { Calendar } from "@etiquette-ui/calendars";

const DISPLAY_DATE_FORMAT = "iiii, MMM dd, yyyy";

const DateContainer = styled.div`
  flex: 0 0 60%;
  font-size: 1.2rem;
  text-align: center;
`;

const DateSelect = styled.button`
  flex: 0 0 60%;
  font-size: 1.2rem;
  text-align: center;
  border: none;
  background: none;
  cursor: pointer;
  color: ${PRIMARY};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  position: relative;
`;

const CalendarContainer = styled.div<{ headerHeight?: number }>`
  position: absolute;
  top: ${({ headerHeight }) => (!!headerHeight ? `${headerHeight}px` : "0px")};
  display: flex;
  justify-content: center;
  height: 265px;
  left: 0;
  right: 0;
  z-index: 1;
`;

const CalendarBackground = styled.div`
  background: white;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0px 8px 24px #0332693d;
`;

const Header: React.FC<{
  setHeaderHeight: React.Dispatch<React.SetStateAction<number | undefined>>;
  headerHeight: number | undefined;
}> = ({ setHeaderHeight, headerHeight }) => {
  const { selectedLang } = useI18n();
  const containerRef = useRef<HTMLHeadElement>(null);
  const { triggerUpdate, loading } = useUpdate();
  const { currentDate, setDate, minDate } = useDateProvider();
  const { summaryApps } = useApps();
  const { translate } = useI18n();
  const [calendarOpen, setCalendarOpen] = useState(false);

  useEffect(() => {
    setHeaderHeight(containerRef.current?.clientHeight);
  }, [containerRef.current?.clientHeight]);

  const dateChangeEnabled = useMemo(() => {
    return (
      summaryApps &&
      summaryApps?.length > 0 &&
      summaryApps.some((app) => app.blueprint.usesDateChange)
    );
  }, [summaryApps]);

  const onChange = (date: Date) => {
    setDate(date);
    setCalendarOpen(false);
  };

  const onUpdate = () => {
    triggerUpdate();
  };

  return (
    <Container ref={containerRef}>
      <div
        style={{ flex: "0 0 20%" }}
        onClick={currentDate ? () => {} : onUpdate}
      >
        {loading ? (
          <Ring color={PRIMARY} size={20} />
        ) : (
          <Icon
            icon="update"
            styles={{
              svg: { height: "20px", width: "20px" },
              line: {
                fill: "none",
                strokeLinecap: "round",
                strokeLinejoin: "round",
                strokeWidth: "1.5px",
                stroke: currentDate ? DISABLED_BUTTON : PRIMARY,
              },
            }}
          />
        )}
      </div>
      {dateChangeEnabled ? (
        <DateSelect onClick={() => setCalendarOpen(!calendarOpen)}>
          <Icon
            icon="calendar"
            styles={{
              svg: { height: "20px", width: "20px" },
              line: {
                fill: "none",
                strokeLinecap: "round",
                strokeLinejoin: "round",
                strokeWidth: "1.5px",
                stroke: PRIMARY,
              },
            }}
          />
          {currentDate
            ? getFormattedDate(currentDate, DISPLAY_DATE_FORMAT)
            : `${translate("TODAY")}, ${getToday(
                selectedLang,
                "MMM dd, yyyy"
              )}`}
        </DateSelect>
      ) : (
        <DateContainer>
          {getToday(selectedLang, DISPLAY_DATE_FORMAT)}
        </DateContainer>
      )}
      <ShowMenu />
      {calendarOpen && (
        <CalendarContainer headerHeight={headerHeight}>
          <CalendarBackground>
            <Calendar
              language={selectedLang}
              selected={currentDate ? currentDate : new Date()}
              formatDate={"MMMM dd"}
              onChange={(date: Date) => onChange(date)}
              maxDate={new Date()}
              minDate={minDate}
              containerProps={{
                style: {
                  paddingTop: "10px",
                },
              }}
            />
          </CalendarBackground>
        </CalendarContainer>
      )}
    </Container>
  );
};

export default Header;
